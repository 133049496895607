<template>
	<div>
		<CustomTable
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="$t('UsersFilter.search')"
			:label-date="$t('UsersFilter.createdDate')"
			:show-create-button="true"
			:text-create-button="$t('UserButtons.create')"
			@create="create"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="users && users.length">
					<tr
						v-for="(user, index2) in users"
						:key="`${user.id}-${index2}`"
						:class="{ 'highlight-row': user.id === idHighlightRow }"
					>
						<td scope="row" class="text-break text-center see-more">
							{{ user.name }}
						</td>
						<td scope="row" class="text-break text-center fit">
							{{ user.phone }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ user.email }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ convertRole(user.role) }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ getStatus(user.status) }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ convertDateToDateVN(user.createdDate) }}
						</td>
						<td scope="row" class="fit text-center actions">
							<router-link
								:to="{
									name: 'UpdateUser',
									params: { id: user.id },
								}"
								class="link-icon"
							>
								<span
									class="prefix-input d-inline-block mr-3"
									:class="{ 'mr-1-75rem': !displayIconCancel(user) }"
								>
									<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
							<template v-if="displayIconCancel(user)">
								<span
									v-if="checkUserActive(user.status)"
									class="prefix-input d-inline-block"
									@click="handleActiveUser(user.id, USER_STATUS.DEACTIVATE)"
								>
									<font-awesome-icon
										:icon="['fas', 'times']"
										class="cancel-icon"
										:title="$t('Action.Inactive')"
									/>
								</span>
								<span
									v-else
									class="prefix-input d-inline-block"
									@click="handleActiveUser(user.id, USER_STATUS.ACTIVE)"
								>
									<font-awesome-icon
										:icon="['fas', 'check']"
										class="cancel-icon"
										:title="$t('Action.Active')"
									/>
								</span>
							</template>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="11" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal
			v-if="showConfirmDelete"
			:title="getTitleModal()"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Cancel')"
			@submit-modal="confirm"
			@cancel-modal="cancel"
		>
			<template #content>
				{{ getBodyModal() }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { GET_USERS, DELETE_USER } from "../store/action-types"
import { RESET_FILTERS_USER_LIST } from "../store/mutation-types"
import { USER_ROLE, USER_STATUS } from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
import { startCase, capitalize } from "lodash-es"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("users")

export default {
	name: "UserManagement",
	data() {
		return {
			USER_STATUS,
			startCase,
			capitalize,
			showConfirmDelete: false,
			userId: null,
			actionType: null,
			headers: [
				{
					title: this.$t("UsersFieldTable.name"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersFieldTable.phone"),
					class: "text-nowrap title text-center fit",
				},
				{
					title: this.$t("UsersFieldTable.email"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersFieldTable.role"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersFieldTable.status"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersFieldTable.createdDate"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("UsersFieldTable.actions"),
					class: "fit text-center",
				},
			],
		}
	},
	computed: {
		...mapState(["users", "totalItems", "totalPages", "recentFilters", "idHighlightRow"]),
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		displayWithRole() {
			if (this.currentUser.role !== USER_ROLE.SUPER_ADMIN) {
				return false
			}
			return true
		},
	},
	created() {},
	beforeDestroy() {
		if (!this.$route.path.includes("users")) this.RESET_FILTERS_USER_LIST()
	},
	methods: {
		...mapActions({
			GET_USERS,
			DELETE_USER,
		}),
		...mapMutations({ RESET_FILTERS_USER_LIST }),
		create() {
			this.$router.push({ name: "CreateUser" })
		},
		search: async function (params = {}) {
			await this.GET_USERS(params)
		},
		async confirm() {
			const params = {
				id: this.userId,
				status: this.actionType,
			}
			await this.DELETE_USER(params)
			this.showConfirmDelete = false
			this.userId = null
			this.actionType = null
			this.$store.commit("set", ["modalShow", false])
			this.search(this.recentFilters)
		},
		cancel() {
			this.showConfirmDelete = false
		},
		handleActiveUser(id, type) {
			this.userId = id
			this.actionType = type
			this.showConfirmDelete = true
			this.$store.commit("set", ["modalShow", true])
		},
		displayIconCancel(user) {
			if (user.role !== USER_ROLE.SUPER_ADMIN && this.currentUser?.role === USER_ROLE.SUPER_ADMIN) {
				return true
			}
			return false
		},
		checkUserActive(status) {
			if (status === USER_STATUS.ACTIVE) {
				return true
			}
			return false
		},
		getStatus(status) {
			if (status === USER_STATUS.DEACTIVATE) {
				return this.$t("UsersFilter.inactive")
			}
			return capitalize(status)
		},
		getTitleModal() {
			if (this.actionType === USER_STATUS.ACTIVE) {
				return this.$t("UserMessage.Header.Active")
			}
			return this.$t("UserMessage.Header.Inactive")
		},
		getBodyModal() {
			if (this.actionType === USER_STATUS.ACTIVE) {
				return this.$t("UserMessage.Body.Active")
			}
			return this.$t("UserMessage.Body.Inactive")
		},
		convertRole(role) {
			if (role === USER_ROLE.QA) {
				return role
			}
			return capitalize(startCase(role))
		},
	},
}
</script>

<style></style>
